const UNLOADING1C = {
  INFO_AMOUNTS_SUPPLIER: 'infoAmountsSupplier',
  SET_REPORT_TYPE: 'setReportType',
  SET_ERROR: 'setError',
  SET_WORKSHEETS: 'setWorkSheets',
  SET_START_DATE: 'setStartDate',
  SET_END_DATE: 'setEndDate',
  SET_COMPANY: 'setCompany',
  SET_COUNTERAGENT: 'setCounteragent',
  SET_TYPE_IMPLEMENTATION: 'setTypeImplementation',
  SET_DISABLED_MONTH: 'setDisabledMonth',
  SET_STEP: 'setStep',
  SET_GROUPING_TYPE: 'setGroupingType',
  SET_DAY_TYPE: 'setDayType',
  SET_AMOUNT_TYPE: 'setAmountType',
  SET_NOMENCLATURE: 'setNomenclature',
  SET_COMMENT: 'setComment',
  SET_PROVIDER: 'setProvider',
  SET_PRINCIPALS: 'setPrincipals',
  SET_PRINCIPAL: 'setPrincipal',
  SET_FILELINK: 'setFileLink',
  SET_SHEET: 'setSheet',
  SET_TRIP_ITEM_COLUMN_NAME: 'setTripItemColumnName',
  RESET: 'resetStore',
};

export default UNLOADING1C;
