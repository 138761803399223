const RAILWAY_WARNING = {
  GET_LIST_TEMPLATE_WARNING: 'getListTemplateWarning',
  CHANGE_FIELD_TEMPLATE_WARNING: 'changeFieldTemplateWarning',
  ADD_FIELD_TEMPLATE_WARNING: 'addFieldTemplateWarning',
  DELETE_FIELD_TEMPLATE_WARNING: 'deleteFieldTemplateWarning',
  SET_ADDITION_FIELD_WARNING: 'setAdditionFieldWarning',
  SET_SERVER_ERROR: 'setServerError',
  SET_IS_EDDITING: 'setIsEdditing',
  SET_TEXT_TEPMLATE_WARNING: 'setTextTemplateWarning',
};

export default RAILWAY_WARNING;
