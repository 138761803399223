import APP from './app';
import USER from './user';
import WORKSPACE from './workspace';
import FILTER from './filter';
import ACCOUNT from './account';
import AIRLINE_CANCEL from './airlineCancel';
import AIRLINE_WARNING from './airlineWarning';
import COMPANIES from './companies';
import EMPLOYEES from './employees';
import EMPLOYEE from './employee';
import COMPANY from './company';
import TRIP from './trip';
import HOTELS from './hotels';
import SOCKET from './socket';
import BUSINESS from './business';
import SEARCHUSERS from './searchUsers';
import CARTSANDNOTES from './cartsAndNotes';
import REVISEACTTEST from './reviseActTest';
import UNLOADING1C from './unloading1c';
import SENDINGDIADOC from './sendingDiadoc';
import NOTIFICATIONS from './notifications';
import FEATUREFLAGS from './featureFlags';
import AMOCRM from './amocrm';
import CALLS from './calls';
import TRIP_TRAIN from './tripTrain';
import TRAIN_CANCEL from './trainCancel';
import REGIONS from './regions';
import RAILWAY_WARNING from './railwayWarning';
import WARNING from './warning';
import DOCUMENTS_CONSTRUCTOR from './documentsConstructor';

const ACTIONS = {
  STOREINIT: 'STORE_INIT',
  APP,
  AIRLINE_CANCEL,
  AIRLINE_WARNING,
  USER,
  WORKSPACE,
  FILTER,
  ACCOUNT,
  COMPANIES,
  EMPLOYEES,
  EMPLOYEE,
  COMPANY,
  TRIP,
  HOTELS,
  SOCKET,
  BUSINESS,
  SEARCHUSERS,
  CARTSANDNOTES,
  REVISEACTTEST,
  UNLOADING1C,
  SENDINGDIADOC,
  NOTIFICATIONS,
  FEATUREFLAGS,
  AMOCRM,
  CALLS,
  TRIP_TRAIN,
  TRAIN_CANCEL,
  REGIONS,
  RAILWAY_WARNING,
  WARNING,
  DOCUMENTS_CONSTRUCTOR,
};

export default ACTIONS;
