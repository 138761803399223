const SENDINGDIADOC = {
  INFO_AMOUNTS_SUPPLIER: 'infoAmountsSupplier',
  LOAD_LIST_COMPANY: 'loadlistcompany',
  CHANGE_DATE: 'changeDate',
  GET_STATUS: 'getStatus',
  SELECT_STATUS: 'selectStatus',
  SELECT_PERIOD: 'selectPeriod',
  SELECT_FILTER: 'selectFilter',
  CHANGE_CURRENT_PAGE: 'changeCurrentPage',
  UPDATE_ALL_CHECKBOX: 'updateAllCheckbox',
  UPDATE_CHECKBOX: 'updateCheckbox',
  RESET: 'reset',
};

export default SENDINGDIADOC;
