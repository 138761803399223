const HOTEL = {
  INPUT_SEARCH: 'INPUT_SEARCH_HOTEL',
  CHANGE_FIELD: 'CHANGE_FIELD_HOTELS',
  GET_HOTEL: 'GET_HOTEL',
  GET_REGION: 'GET_REGION',
  DO_REQUEST: 'DO_REQUEST',
  ADD_REGION: 'ADD_REGION',
  RESET: 'RESET_R',
  UPDATE_BAR: 'UPDATE_BAR',
  SEARCH_REGIONS: 'SEARCH_REGIONS',
};

export default HOTEL;
