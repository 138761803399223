const TRIP_TRAIN = {
  CHANGE_FIELD: 'CHANGE_FIELD',
  UPLOAD_FILE: 'UPLOAD_FILE',
  UPLOAD_FILE_FAIL: 'UPLOAD_FILE_FAIL',
  UPLOAD_FILE_RESET: 'UPLOAD_FILE_RESET',
  RESET_ERROR: 'RESET_ERROR',
  GET_TRAIN_CARRIERS: 'GET_TRAIN_CARRIERS',
  GET_TRAIN_CARRIERS_FAIL: 'GET_TRAIN_CARRIERS_FAIL',
  RESET_TRAIN_STORE: 'RESET_TRAIN_STORE',
  GET_CANCEL_TRIP_INFO: 'GET_CANCEL_TRIP_INFO',
  GET_CANCEL_TRIP_INFO_FAIL: 'GET_CANCEL_TRIP_INFO_FAIL',
  UPDATE_CANCEL_LOADING: 'UPDATE_CANCEL_LOADING',
};

export default TRIP_TRAIN;
